<template>
  <b-modal
    id="modal-create-question-group"
    title="Tạo bộ câu hỏi"
    centered
    hide-footer
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <basic-input
          label="Mã bộ câu hỏi"
          placeholder="Nhập mã bộ câu hỏi"
          name="code"
          :value.sync="form.code"
          :required="true"
          :state="validateState('code')"
          :invalidFeedback="errors.first('code')"
          v-validate="'required|alpha_num|max:50'"
          data-vv-as="Mã bộ câu hỏi"
          maxlength="50"
        ></basic-input>
        <basic-input
          label="Tên bộ câu hỏi"
          placeholder="Nhập tên bộ câu hỏi"
          name="name"
          :value.sync="form.name"
          :required="true"
          :state="validateState('name')"
          :invalidFeedback="errors.first('name')"
          v-validate="'required|max:100'"
          data-vv-as="Tiêu đề bộ câu hỏi"
          maxlength="100"
        ></basic-input>
        <div class="d-flex justify-content-end align-items-center">
          <b-button class="btn" type="button" @click="resetModal">
            Huỷ
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { CREATE_QUESTION_GROUP } from '@/core/services/store/survey.module';
const { mapActions } = createNamespacedHelpers('survey');
export default {
  name: 'ModalCreate',
  data() {
    return {
      form: {
        code: null,
        name: null,
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({ CREATE_QUESTION_GROUP }),
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    async submit() {
      this.loading = true;
      const params = {
        code: this.form.code,
        name: this.form.name,
        quesitons: [],
      };
      const { error, id } = await this.CREATE_QUESTION_GROUP(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Thêm mới thành công',
        });
        this.$router.push({
          name: 'survey_quize_edit',
          params: {
            id,
          },
        });
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
      this.loading = false;
      this.resetModal();
    },
    resetModal() {
      this.form = {
        code: null,
        name: null,
      };
      this.$bvModal.hide('modal-create-question-group');
    },
  },
};
</script>
<style lang=""></style>
